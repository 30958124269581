
*{
  scroll-behavior: smooth;
 }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.label-clr{
  color: white;
  font-family: creamy coconut;
  font-size: 30px;
}
.contact-root{
  margin: 0 !important;
  background: rgb(37, 36, 36);
 
}

.form-control{
  background-color: #E6E6E6 !important;
  /* color: #fff !important; */
  color: black !important ;
  border-radius: 32px !important;
}
tr{
  color: white;
}

#regForm h1, #regForm h2, #regForm p, #regForm div{
color: #fff !important;
}

::-webkit-input-placeholder { /* Edge */
  color: rgb(50, 45, 45);
}

:-ms-input-placeholder { /* Internet Explorer */
  color: rgb(50, 45, 45);
}

::placeholder {
  color: rgb(50, 45, 45);
}
@media (max-width: 600px) {
#regForm {
  margin: 0px auto;
  font-family: Raleway;
  padding: 0px !important;
  border-radius: 10px;
}
.mob-res{
  margin-bottom: 100px;
}
}
#root{
  background: rgb(50, 50, 50) !important;
  min-height: 100vh !important; 
}

.ant-input-group .ant-input {
  height: 43px;
}
.ant-input-group-addon{
  border-top-left-radius: 32px !important;
  border-bottom-left-radius:32px !important
}
.input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
select:invalid{
  color: rgb(111, 103, 103) !important;
}
.ant-btn:hover{
  color: black !important;
}
.btn-com:hover{
  color: black !important;
  background-color: white;
}
.billingAddress div p{
color: white !important;
}