* {
  margin: 0;
  padding: 0px;
}

.card {
  flex-direction: row;
}

.card-body {
  width: 100%;
}

.card iframe {
  width: 50%;
  align-items: top;
}

.card-div>img {
  width: 100% !important;

}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/


#header #logo {
  float: left;
}

@-moz-document url-prefix() {
  .remove-me {
    border: 1px solid red;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.single-image {
  z-index: 997;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-family: "Poppins-Black", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}



#header.header-transparent {
  background: transparent;
}

#header.header-fixed {
  background: rgba(52, 59, 64, 0.9);
  padding: 20px 0;
  height: 72px;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  /* background: url(../../images/goals/goalsTwo.jpg) top center; */
  background-size: cover;
  position: relative;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}



#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#hero h1 {
  margin: 30px 0 10px 0;
  font-size: 110px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}

#hero h2 {
  margin: 30px 0 10px 0;
  font-size: 80px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;

}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 46px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 40px;
    line-height: 36px;
  }
}



@media (max-width: 768px) {
  #hero h2 {
    font-size: 37px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

#hero .btn-get-started {
  font-family: "Poppins-Black", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #2dc997;
  border: 2px solid #2dc997;
}

/* 
Arrow icon 
*/

.arrow-icon {
  height: 2.8em;
  width: 2.8em;
  display: block;
  padding: 0.5em;
  margin: 5em 0px 0px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.left-bar {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 40px;
  height: 5px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;

}

.right-bar {
  position: absolute;
  background-color: white;
  top: 0px;
  left: 30px;
  width: 40px;
  height: 5px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;

}

.explore {
  background: rgb(37 36 36 / 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.typo {
  font-family: Poppins-Black;
  font-style: normal;
  font-weight: 800;
  font-size: 150px;
  line-height: 450px;
  color: black;
  text-shadow: -1px 0 rgba(255, 255, 255, 0.23), 0 1px rgba(255, 255, 255, 0.23), 1px 0 rgba(255, 255, 255, 0.23), 0 -1px rgba(255, 255, 255, 0.23);
  /* -webkit-text-stroke: 4px solid rgba(255, 255, 255, 0.23) */
}

.explore-typo {
  position: absolute;
  font-size: 72px;
  line-height: 88px;
  letter-spacing: 0.38em;
  color: #FFFFFF;
}

@media (max-width: 500px) {
  .typo {
    font-size: 50px;

  }

  .explore-typo {
    font-size: 30px;

  }
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  background: rgb(0 0 0 / 0%) !important;
  padding: 30px 0;
}

#portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.welcome-text {
  font-size: 160px;
}

#portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 12px 18px 14px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #666666;
  margin: 0 5px 10px 5px;
  background: rgb(211, 200, 200);
  border-radius: 4px;
}

#portfolio #portfolio-flters li:hover,
#portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #2dc997;
}

#portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

#portfolio .text-item {
  margin-bottom: 80px;
  overflow: hidden;

}

#portfolio .text-item img {
  position: relative;
  top: 0;
  height: 400px;
}
#portfolio .text-item .book-info:hover{
  background-color:black;
  color: white;
}
#portfolio .text-item .book-info {
  opacity: 1;
  position: absolute;
  /* left: 12px; */
  right: 24px;
  bottom: 20px;
  z-index: 3;
  padding: 1px 20px;
  height: 39px;
  /* width: 135px; */
  color: black;
  font-family: open sans;
  /* background: rgba(34, 32, 32, 0.562);
     */
  /* border-radius: 0px 0px 10px 10px;
     */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 4px dashed #4D6646;
  border-radius: 20px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #portfolio .text-item .book-info {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

#portfolio .text-item .book-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

#portfolio .text-item .book-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}

#portfolio .text-item .book-info .preview-link,
#portfolio .text-item .book-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: white;
}

#portfolio .text-item .book-info .preview-link:hover,
#portfolio .text-item .book-info .details-link:hover {
  color: #a4ebd4;
}

#portfolio .text-item .book-info .details-link {
  right: 15px;
}

/* #portfolio .text-item img {
  top: -10px;
} */

/* #portfolio .text-item:hover .book-info {
  opacity: 1;
  bottom: 0;
} */

.portfolio-details {
  padding-top: 30px;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #2dc997 !important;
}

.portfolio-details .book-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  z-index: 2;
}

.portfolio-details .book-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .book-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .book-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 808px) {
  .portfolio-details .portfolio-description h2 {
    width: 100%;
  }

  .portfolio-details .book-info {
    position: static;
    margin-top: 30px;
  }

}

.text-name {
  position: absolute;
  font-family: Poppins-Black;
  font-style: normal;
  font-weight: 800;
  font-size: 63px;
  line-height: 150px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.1);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.23);
  top: -10px;
  left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 91%;
}

.root-about {
  max-width: 100%;
}

@media (max-width: 500px) {

  .text-name {
    top: -10px;
    left: 20px;
    font-size: 40px;
  }
}

::-webkit-scrollbar {
  width: 0px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.img-responsive {
  position: absolute;
  top: -85px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  left: 21.5% !important;
  border: 6px solid white;
}

.jt-justify {
  text-align: start;
}

.jt-init {
  text-align: initial;
}

.jt-end {
  text-align: end;
}

.head {
  color: rgb(94, 133, 100);
  font-size: 80px;
}

@media (max-width: 600px) {
  .head {
    color: rgb(94, 133, 100);
    font-size: xx-large;
    /* margin-top: -30px; */
  }

  .mb-30 {
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }

  .member {
    margin-bottom: 100px !important;

  }

  #hero {
    width: 100%;
    height: 70vh;

  }

  .typo {
    line-height: unset !important;
  }

  .img-responsive {
    position: absolute;
    top: -120px;
    width: 50%;
    height: 14% !important;
    border-radius: 50%;
    /* left: 25%; */
    border: 6px solid white;
    left: 35% !important;
  }

  #portfolio .text-item {
    overflow: hidden;
    margin-left: 5%;
    width: 97% !important;

  }
}

.img-fluid {
  z-index: 1;
  border-radius: 10px;
}

.first-img {
  margin-bottom: 10px;
}

.first-img:after {
  content: '';
  position: absolute;
  bottom: -10px;
  top: 50%;
  left: 50%;
  width: 52%;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

.second-img {
  margin-bottom: 10px;

}

.second-img:after {
  content: '';
  position: absolute;
  bottom: -10px;
  top: 50%;
  left: 0px;
  left: -3%;
  width: 50%;
  margin-bottom: -10px;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
}

.back-one {
  position: absolute;
  width: 200.14px;
  margin: -551px 39%
}

.back-two {
  position: absolute;
  width: 200.14px;
  height: 120px;
  margin: -230px -39%;
}

.back-three {
  position: absolute;
  width: 200.14px;
  height: 120px;
  margin: 207px 20%
}

.back-four {
  position: absolute;
  width: 200.14px;
  height: 120px;
  left: -40px;
  top: -50px;
  z-index: 0;
}

.back-five {
  position: absolute;
  width: 200.14px;
  height: 120px;
  top: -50px;
  right: -70px;
  z-index: 0;
}

.back-six {
  position: absolute;
  width: 200.14px;
  height: 120px;
  margin: 580px 21%
}

@-moz-document url-prefix() {
  #portfolio .text-item .book-info {
    background-color: rgba(16, 16, 16, 0.8) !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap;

  }

  .back-one {
    position: absolute;
    width: 200.14px;
    margin: -1098px 44% 14px 117% !important;
  }

  .back-two {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: -466px 79% 0px 0px !important
  }

  .back-three {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: 207px 20% -192px 53%;
  }

  .back-four {
    position: absolute;
    width: 200.14px;
    height: 120px;
    left: -40px;
    top: -50px;
    z-index: 0;
  }

  .back-five {
    position: absolute;
    width: 200.14px;
    height: 120px;
    top: -50px;
    right: -70px;
    z-index: 0;
  }

  .back-six {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: 580px 21% -624px 60%
  }
}

@media all and (min--moz-device-pixel-ratio:0) and (max-width: 1370px) {
  .back-one {
    position: absolute;
    width: 200.14px;
    margin: -1098px 44% 14px 117% !important;
  }

  .back-two {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: -424px 79% 0px 0px !important
  }

  .back-three {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: 207px 20% -192px 53%;
  }

  .back-four {
    position: absolute;
    width: 200.14px;
    height: 120px;
    left: -40px;
    top: -50px;
    z-index: 0;
  }

  .back-five {
    position: absolute;
    width: 200.14px;
    height: 120px;
    top: -50px;
    right: -70px;
    z-index: 0;
  }

  .back-six {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: 580px 21% -624px 60% !important
  }
}

@media (max-width: 600px) {

  .back-one,
  .back-two,
  .back-three,
  .back-four,
  .back-five,
  .back-six {
    display: none;
  }

}

@media (max-width: 786px) {

  .back-one,
  .back-two,
  .back-three,
  .back-four,
  .back-five,
  .back-six {
    display: none;
  }

}

@media (max-width: 1370px) {

  .img-responsive {
    position: absolute;
    top: -85px;
    width: 50%;
    height: 14%;
    border-radius: 50%;
    border: 4px solid white;
  }

  #portfolio .text-item {
    margin-bottom: 45px;
    overflow: hidden;

  }

  .back-two {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: -210px -39%;

  }

  .back-one {
    position: absolute;
    width: 200.14px;
    margin: -500px 39%
  }

  .back-six {
    position: absolute;
    width: 200.14px;
    height: 120px;
    margin: 636px 23%
  }


}

.mb-30 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media (min-width: 767px) and (max-width: 1100px) {

  .member {
    height: 1100px;

  }

  .back-six {
    width: 150.14px;
    height: 70px;
    margin: 888px 23%
  }

  .img-responsive {
    position: absolute;
    width: 167px !important;
    height: 14% !important;
    border-radius: 50%;
    left: 23% !important;
    border: 4px solid white;
  }

  .text-name {
    position: absolute;
    font-family: Poppins-Black;
    font-style: normal;
    font-weight: 800;
    font-size: 36px !important;
    line-height: 150px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.1);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.23);
    top: -10px;
    left: 57px;
  }



}

@media (max-width: 900px) {

  .member {
    height: 1500px;

  }

  .back-six {
    width: 150.14px;
    height: 70px;
    margin: 888px 23%
  }


}

@media (min-width: 600px) and (max-width: 767px) {
  #header #logo h1 {
    font-size: 26px;
  }

  #header #logo img {
    max-height: 40px !important;
  }

  .img-responsive {
    left: 40% !important;
    top: -115px !important;
    width: 236px !important;
    height: 14% !important;

  }

  .member {
    height: 100% !important;
  }
}

.start {
  text-align: start;
}

.imgPad {
  padding: 30px 15px 0 40px !important;
}

.img-book {
  border-radius: 20px 60px;
}
.btn-read{
  color: black;
  font-size: 20px;
  font-weight: bold;
}