.date-filter {
    background: rgb(135 132 132) !important;
    border-radius: 10px !important;
    border: 0px !important;
    padding: 7px !important;
}

tr {
    border: 1px solid white !important;
    padding: 10px
}

.hCBnvI {
    background-color: rgb(59, 58, 58) !important;
    color: white !important;
    font-size: 18px;

}

.rdt_TableRow {
    background-color: #646464 !important;
    color: white !important;
    border: 1px solid white;
    font-size: 16px !important
}

.rdt_Pagination {
    background-color: #252424 !important;
    color: white !important;
}

.gPLhoV svg {
    fill: white !important;

}

.eBauma {
    background-color: rgb(59, 58, 58) !important;
    color: white !important;
    font-family: open sans;
}

.hLGqlg {
    font-family: open sans;

}

.hkMDrI {
    font-family: open sans;

}

@media screen {
    .print-screen {
        /* overflow: hidden; height: 0;
         */
        display: none;
    }
}

.react-datepicker-ignore-onclickoutside {
    background-color: #E6E6E6 !important;
    color: black !important;
    border-radius: 32px !important;
}

.ant-select-selection--single {
    border-radius: 32px !important;
    height: 50px;
    padding-top: 10px;
    color: black;
}

.ifOHjV {
    border-radius: 32px !important;
}

.gelpCx,
.embYUS {
    border-radius: 32px !important;
    border: 1px solid white
}

/* table columns hedden */
.eUeqdG,
.dFsTSm {
    /* padding-left: 35px; */
    width: 173px;
}

.gZWIKX,
.idPHNo,
.eOtTIX {
    background-color: #646464 !important;
    color: white !important;
    font-size: 18px !important;

}

.bvxQGL:disabled,
.bvxQGL {
    fill: white !important;

}

.css-k008qs,
.css-heg063-MuiTabs-flexContainer {
    /* justify-content: center; */
    /* margin-left: 44%; */
}

.date-picker-text {
    width: 30%;
}



@media (max-width: 1200px) {

    .css-k008qs,
    .css-heg063-MuiTabs-flexContainer {
        /* margin-left: 9%; */
    }
}

.ant-table-thead>tr>th {
    background-color: #646464 !important;
    color: white !important;
}

.ant-table-thead>tr:first-child>th:first-child {
    border-top-left-radius: 32px !important;
}

.ant-table-thead>tr:first-child>th:last-child {
    border-top-right-radius: 32px !important;
}

.ant-table-tbody {
    background-color: #646464 !important;
    color: white !important;
}

/* tr:last-child { background:#ff0000 !important} */
textarea.ant-input {
    min-height: 100px;
    width: 90%;
    border-radius: 32px;
    margin: 30px;
    padding: 30px;
    /* margin-top: 30px; */
}

tr:last-child>th:last-child {
    border-top-right-radius: 32px;
}

tr:last-child>th:first-child {
    border-top-left-radius: 32px !important;
}

.modal-td {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.btn-ex {
    display: inline-block;
    float: right;
    width: "100%"
}
.date-picker-text-01{
    width: 100% !important;
    /* margin-bottom: 20px; */
}
.date-picker-text-02 {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 60%;
    /* margin-bottom: 10px;/ */

}
@media (max-width: 500px) {
    .d-1{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .date-picker-text{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80% !important;
        /* margin-bottom: 10px; */
    }
    .date-picker-text-01{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-bottom: 10px;
    }
    .date-picker-text-02 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80% !important;
        margin-bottom: 10px;

    }
    .btn-ex {
        display: flex;
        /* float: right; */
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

    }
}