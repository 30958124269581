.q_a{
    width: 100%;
    background-color:  "#333" ;
    margin-top: 50px;
}
.head_title{
    /* height: 40px; */
    justify-content: center;
    text-align: start;
    /* padding-left: 30px; */
}
#example-collapse-text{
    background-color: #515050 !important;
}
.show{
    background-color: rgba(37, 36, 36) !important;;
}
.top-mrg{
    margin-top: 10px;
}
.step-2{
    margin: 70px 0px 0px
}
@media (max-width: 500px) {
    .step-2{
        margin: 0px
    }
}