.form-control-login{
    border-radius: .7rem;
    padding: 0rem 0rem 0 .5rem;
   
   height: calc(3rem + 0.75rem + 1px);
   font-size: 14px !important;
   background-color: rgb(75, 75, 75);
  }

  .loginSign .btn-block {
    width: 100% !important;
    border-radius: 5px !important;
    background: #C58151;
    border-color: #C58151;
    padding: 10px;
    font-size: 18px;
  }
  .loginSign .card-body {
    padding: 3.25rem !important;
    border-radius: 15px !important;
  }
  .btn-danger:active:focus{
    box-shadow: 0 0 0 0px !important;
  }

 select:invalid{
  color: rgb(111, 103, 103) !important;
}
  /*Tab Start*/
  
  .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgb(111, 103, 103);
  }
  .form-control::-moz-placeholder { /* Firefox 19+ */
    color: rgb(111, 103, 103);
  }
  .form-input:-ms-input-placeholder { /* IE 10+ */
    color: rgb(111, 103, 103);
  }
  .form-input:-moz-placeholder { /* Firefox 18- */
    color: rgb(111, 103, 103);
  }
  .label-wht{
    color:white;
    /* font-family: open sans; */
    margin-top: 20px;
  }