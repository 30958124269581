.user-table {
    background-color: rgb(50, 50, 50);
}

.table-responsive {
    background-color: rgb(50, 50, 50) !important;
    font-family: open sans;

}

th {
    padding: 10px;
    color: white;
}

/* tr:hover {
    color: white !important;
    background-color: rgb(50, 50, 50) !important;
} */

td {
    color: white !important;
    background-color: #646464 !important;
}


.widges {

    color: white !important;
    background-color: rgb(50, 50, 50) !important;

}

.kntihR {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: white !important;
    background-color: #4b4545 !important;
}

.MuiAppBar-colorDefault {
    background-color: #C68252 !important;
    margin-top: 2% !important;
}

.PrivateTabIndicator-colorPrimary-2 {
    background-color: #46697E !important;
}

.MuiTab-textColorPrimary {
    color: #fff !important;
    font-weight: 700 !important;
}

/* .MuiTab-textColorPrimary.Mui-selected {
    color: #e9eff4 !important;
} */
.table-responsive {
    border-radius: 32px;
    background-color: #646464 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 3px solid #FFFFFF !important;


}
/* td{
    border: 1px solid #FFFFFF !important;
} */
/* .ant-pagination-item-link{
    color: black !important;
    
} */
.ant-pagination-item-link> .anticon{
    margin-top: 9px !important;
}
.ant-pagination-item >a{
    color: white !important;
    margin-top: 5px !important;

}
.ant-pagination-item-active >a{
    color: skyblue !important;
    margin-top: 5px !important;
}
tr:last-child >td:last-child{
    border-bottom-right-radius: 32px;
}
tr:last-child >td:first-child{
    border-bottom-left-radius: 32px;
}
label{
    color: white;
}