/**
 * Circular menu v2
 */
 * { margin: 0; padding: 0; }
 /* html { font: 100%/1.3 Century Gothic, Verdana, sans-serif; } */
 /* body { overflow: hidden; min-width: 20em; } */
 .menuopt { display: none; }
 .circle, .circle:before, .circle:after { border-radius: 50%; }
 .seasonal-cal {
     margin: 5em auto;
     min-width: 16em; width: 72%; max-width: 40em; 
  }
 .seasonal-cal ul {
     position: relative;
     padding: 50%;
     max-width: 0; max-height: 0;
     box-shadow: 0 0 .5em rgba(0,0,0,.5);
     list-style: none;
     background: white;
 }
 .seasonal-cal li {
     position: absolute;
     transition: 2s;
 }
 .slice {
     overflow: hidden;
     position: absolute;
     top: 0; 
     left: 0;
     opacity: 1;
     color: black;
     width: 50%;
      height: 50%;
     transform-origin: 100% 100%;
 }
 .unsel {
     top: 34%; left: 34%;
     width: 32%; height: 32%;
     box-shadow: 0 0 .75em;
     background: paleturquoise;
 }
 .coconut { transform: skewX(50deg); }
 .vanilla { transform: rotate(40deg) skewX(50deg); }
 .orange { transform: rotate(80deg) skewX(50deg); }
 .almond { transform: rotate(120deg) skewX(50deg); }
 .grape { transform: rotate(160deg) skewX(50deg); }
 .blackberry { transform: rotate(-160deg) skewX(50deg); }
 /* .cherry { transform: rotate(-120deg) skewX(50deg); }
 .strawberry { transform: rotate(-80deg) skewX(50deg); }
 .raspberry { transform: rotate(-40deg) skewX(50deg); }
 .magnifiable { height: 100%; } */
 .seasonal-cal label { cursor: pointer; }
 .slice label {
     display: block;
     width: 200%; height: 200%;
     transform: skew(-50deg) rotate(-70deg);
     box-shadow: 0 0 .1em black;
     opacity: 1;
     color: transparent;
     font-size: .8em;
     line-height: 1.9;
     text-align: center;
     text-decoration: none;
     transition: 1s;
 }
 .slice label:before {
     position: absolute;
     top: 10%; left: 10%;
     width: 80%; height: 80%;
     border-radius: 50%;
     box-shadow: 0 0 1em rgba(0,0,0,.5);
     background-position: 50% 0;
     background-repeat: no-repeat;
     background-size: 35% 35%;
     content: '';
 }
 .slice label:hover { opacity: 1; }
 .dark label:hover { color: white; }
 .light label:hover { color: black; }
 .unsel label {
     display: block;
     height: 100%;
     background: radial-gradient(rgba(0,0,0,.5), transparent 50%);
 }
 .coconut label, .ococonut:checked ~ .seasonal-cal .unsel { background: #f4f5fa; }
 .vanilla label, .ovanilla:checked ~ .seasonal-cal .unsel { background: #ffeb98; }
 .orange label, .oorange:checked ~ .seasonal-cal .unsel { background: #ffb038; }
 .almond label, .oalmond:checked ~ .seasonal-cal .unsel { background: #d3a573; }
 .grape label, .ogrape:checked ~ .seasonal-cal .unsel { background: #d9e260; }
 .blackberry label, .oblackberry:checked ~ .seasonal-cal .unsel { background: #833a68; }
 .cherry label, .ocherry:checked ~ .seasonal-cal .unsel { background: #b1001d; }
 .strawberry label, .ostrawberry:checked ~ .seasonal-cal .unsel { background: #ec0404; }
 .raspberry label, .oraspberry:checked ~ .seasonal-cal .unsel { background: #ef6982; }
 /* .coconut label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Cocos_nucifera00.jpg/320px-Cocos_nucifera00.jpg); }
 .vanilla label:before { background-image: url(http://www.learningherbs.com/image-files/vanilla-extract-7.jpg); }
 .orange label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/OrangeBloss_wb.jpg/250px-OrangeBloss_wb.jpg); }
 .almond label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/8/84/PikiWiki_Israel_7025_Amond_blossom.jpg/170px-PikiWiki_Israel_7025_Amond_blossom.jpg); }
 .grape label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Table_grapes_on_white.jpg/220px-Table_grapes_on_white.jpg); }
 .blackberry label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Ripe%2C_ripening%2C_and_green_blackberries.jpg/220px-Ripe%2C_ripening%2C_and_green_blackberries.jpg); }
 .cherry label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Cherry_Stella444.jpg/220px-Cherry_Stella444.jpg); }
 .strawberry label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/2/29/PerfectStrawberry.jpg/220px-PerfectStrawberry.jpg); }
 .raspberry label:before { background-image: url(http://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Raspberries05.jpg/220px-Raspberries05.jpg); }
 .slice label:hover:after { opacity: 0; } */
 .ococonut:checked ~ .seasonal-cal .coconut { 
     transform: skewX(50deg) scale(1.05); 
    }
 .ovanilla:checked ~ .seasonal-cal .vanilla { transform: rotate(40deg) skewX(50deg) scale(1.05); }
 .oorange:checked ~ .seasonal-cal .orange { transform: rotate(80deg) skewX(50deg) scale(1.05); }
 .oalmond:checked ~ .seasonal-cal .almond { transform: rotate(120deg) skewX(50deg) scale(1.05); }
 .ogrape:checked ~ .seasonal-cal .grape { transform: rotate(160deg) skewX(50deg) scale(1.05); }
 .oblackberry:checked ~ .seasonal-cal .blackberry { transform: rotate(-160deg) skewX(50deg) scale(1.05); }
 .ocherry:checked ~ .seasonal-cal .cherry { transform: rotate(-120deg) skewX(50deg) scale(1.05); }
 .ostrawberry:checked ~ .seasonal-cal .strawberry { transform: rotate(-80deg) skewX(50deg) scale(1.05); }
 .oraspberry:checked ~ .seasonal-cal .raspberry { transform: rotate(-40deg) skewX(50deg) scale(1.05); }
 .ococonut:checked ~ .seasonal-cal .coconut label, 
 .ovanilla:checked ~ .seasonal-cal .vanilla label, 
 .oorange:checked ~ .seasonal-cal .orange label, 
 .oalmond:checked ~ .seasonal-cal .almond label, 
 .ogrape:checked ~ .seasonal-cal .grape label, 
 .oraspberry:checked ~ .seasonal-cal .raspberry label {
     box-shadow: 0 0 .45em rgba(0,0,0,.5);
     opacity: 1; color: black;
 }
 .oblackberry:checked ~ .seasonal-cal .blackberry label , 
 .ocherry:checked ~ .seasonal-cal .cherry label, 
 .ostrawberry:checked ~ .seasonal-cal .strawberry label {
     box-shadow: 0 0 .45em rgba(0,0,0,.5);
     opacity: 1; color: black
 }
 @media (min-width: 25em) { .slice label { font-size: 1em; line-height: 2.3; } }
 @media (min-width: 35em) { .slice label { font-weight: 700; line-height: 2.7; } }
 
 .moonPic{
    width: 228px;
    left: 35%;
    position: relative;
    top: 250px;
   z-index: 0;

}
.moonPic img{
    width: 176px;
    position: absolute;
    z-index: 3;
    left: 114px;
    /* animation: movimiento 5s linear 0s infinite;
    transform:rotateX(6deg) rotateY(6deg) rotateZ(6deg); */
}
.moonPic .circle1{
    top: -59px;
    left: 132px;
    transform: rotate( 
7deg);
    width: 228px;
    position: absolute;
    z-index: 2;
}

  
  
.moonPic .winter{
    width: 228px;
    top: 92px;
    left: 113px;
    transform: rotate( 7deg);
    position: absolute;
    z-index: 2;
}
.moonPic .spring{
    width: 113px;
    position: absolute;
    left: 49px;
    top: -55px;
    transform: rotate( 7deg);

    z-index: 2;

}
.moonPic .autumn{
   left: 279px;
    top: -100px;
    transform: rotate( 
7deg);
    z-index: 1;
    width: 71%;
}
.moonPic .latesummer{
    transform: rotate( 
    6deg);
    left: 106px;
    top: -138px;
    width: 104%;
    z-index: 1;
}
.moonPic .tenagi{
    z-index: 2;
    top:-115px ;
    width: 67%;
    left:364px;
    -webkit-transform: rotate(10deg);
    transform: rotate(8deg);
    cursor: pointer;
}
/* [3] Finally, transforming the image when container gets hovered */
.moonPic .tenagi:hover  {
     transition: 1s;
     /* left: 324px; */
     width: 68% ;
     left: 371px
        
  }
.moonPic .kerrawi{
    width: 148%;
-webkit-transform: rotate(9deg);
transform: rotate(6deg);
z-index: 3;
top:-194px;
left:65px;
cursor: pointer;
}
/* [3] Finally, transforming the image when container gets hovered */
.moonPic .kerrawi:hover  {
   
    transition: 1s;
    width: 153%;
     left: 56px;
      top: -203px
        
  }
.moonPic .earlySummer{
    transform: rotate( 
    5deg);
        left: -20px;
        top: -125px;
        width: 70%;
        z-index: 1;
}
.moonPic .lateSpring{
    transform: rotate( 7deg);
    left: -38px;
    top: 61px;
    width: 69%;
    z-index: 1;
}
.moonPic .teparai{
width: 64%;
top:-159px;
left:-77px;
transform: rotate(6deg);
z-index: 2;
cursor: pointer;
}
.moonPic .teparai:hover{
    top: -170px;
    transition: 1s;
    width: 67%;
    left: -85px;
}
.moonPic .nanga{
    width: 68%;
    top:85px;
    left:-107px;
    -webkit-transform: rotate(  7deg );
    transform: rotate(8deg);
    z-index: 2;
    cursor: pointer;
}
.moonPic .nanga:hover{
    top: 87px;
    transition: 1s;
    width: 69% ;
    left: -114px
}
.moonPic .earlySpring{
    transform: rotate( 
    6deg);
    left: 66px;
    top: 212px;
        width: 101%;
        z-index: 0;
}
.moonPic .winterMonth{
    transform: rotate( 
    6deg);
        left: 264px;
        top: 97px;
        width: 67%;
        z-index: 0;
}
/* .moonPic .pitjan{
    width: 146%!important;
top:310px!important;
left:11px!important;
-webkit-transform: rotate(11deg)!important;
transform: rotate(7deg)!important;
z-index: 2!important;
cursor: pointer;
} */
/* .moonPic .pitjan:hover{
    top: 275px;
    left: 20px;
    transition: 1s;
    width: 135%;
} */
.moonPic .pitjan{
    width: 146%;
  top:310px;
  left:11px;
  transform: rotate(7deg);
  z-index: 2!important;
  cursor: pointer;
  }
.moonPic .pitjan:hover{
    transition: 1s;
    width:153%;
    left: 2px;
    top: 314px
  }
.moonPic .thurangal{
    width: 64%;
    top:137px;
    left:339px;
    -webkit-transform: rotate(12deg);
    transform: rotate(7deg);
    z-index: 2;
    cursor: pointer;
    
   
}
/* [3] Finally, transforming the image when container gets hovered */
.moonPic .thurangal:hover  {
     transition: 1s;
     width: 65%;
     left: 342px
  }