.card-img-top {
    /* height: 30vh; */
}

.tab-menu {
    width: 50%;
    background-color: black;
    font-family: Creamy Coconut;
    font-size: large;
    color: white;
    border: 0px solid white;
}

.tab-menu:active {
    border-bottom: 2px solid red;
}

.nav-tabs {
    background-color: black;
    border: 0px solid white;
}

.nav-tabs .nav-link .active {
    border: 0px solid white;

}

.nav-tabs:active {
    border: 0px solid white;


}

.tab-menu>.nav-item>.nav-link>.active {
    border: 0px solid white;

}

.active {
    background-color: rgb(37, 36, 36) !important;
    color: white !important;
    border: 0 0 2px solid red 0px !important;

}

.tab-content:before {}

.card-events {
    margin: 20px;
    border-radius: 20px;
    background-color: black;
    color: white;
    width: 92%;
    padding: 0px !important;

}

.card-body {
    padding: 0 !important;
    border-radius: 20px;
}

.nav-link:active {
    border: 0px solid grey !important;
}

.card-title {
    color: rgb(94, 133, 100);
}

.controlled-tab-example-tab-upcoming {
    border: 0px 0px 2px 0px solid red;
}

.card-event-form {
    background-color: #212529;
    color: white;
}

.tab-content>.active {
    display: block;
    background-color: rgb(37, 36, 36) !important;
}

/* .rc-time-picker-panel-inner{
    background-color: rgb(37,36,36) !important;
}
.rc-time-picker-panel-input{
    background-color: rgb(37,36,36) !important;
}
.rc-time-picker-panel-select-option-selected{
    background-color: rgb(37,36,36) !important;
} */
.modal-content {
    margin-top: 150px;
}

.back:hover {
    background: rgb(37, 36, 36);
}

.rc-time-picker-panel-combobox {
    color: black;
}

.rc-time-picker-panel-input-wrap {
    color: black;
}

.modal-content {
    background-color: rgb(50, 50, 50);
    color: white;
}

.modal-header {
    border-bottom: 0px;
}

.modal-footer {
    border-top: 0px solid grey;
}

.modal-bo {
    text-align: center;
}

.ant-upload-list-item-info {

    background-color: white !important;
}

.img-curve {
    border-radius: 20px 20px 100px 20px;
}

.btn-com {
    border: 2px dashed #4D6646;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.MuiTab-textColorPrimary.Mui-selected {
    color: #4D6646 !important;
}



.css-1aquho2-MuiTabs-indicator {
    /* left: -10px; */

    /* top: 0;
    height: 64px !important;
    left: 185.97px;
    width: 64px !important;
    background-repeat: no-repeat; */
    /* background: rgba(0, 0, 0, 0)  !important; */
    /* background-color: rgba(0, 0, 0, 0)  !important; */

    /* left: 369.276px; */
    /* width: 219.762px; */
    /* top: 20%; */
    /* top: 43%; */
    /* background-color: transparent !important; */
    /* background: url("../../asset/arrow.png") !important; */
    /* background: none !important; */
    /* background-position: center center; */

}

/* .css-1aquho2-MuiTabs-indicator::after {
    content: '';
    position: absolute;
    left: 50px;
 
    background: url("../../asset/arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
} */

/* .MuiTabs-indicator {
    top: 0;
    height: 64px !important;
    left: 185.97px;
    width: 64px !important;
    background-repeat: no-repeat;
   
    left: 369.276px;
  
    top: 20%;
 
    background: url("../../asset/arrow.png") !important;

} */

/* .MuiTabs-indicator::after {
    content: '';
    position: absolute;
    left: 50px;
 
    background: url("../../asset/arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
} */
.css-1aquho2-MuiTabs-indicator{
    display: none;
}
.MuiTabs-indicator {
    display: none;
}
.css-1gsv261 {
    border-bottom: 0px solid grey !important;
}
.css-ttwr4n {
    top: 0;
    height: 64px !important;
    left: 185.97px;
    width: 64px !important;
    background-repeat: no-repeat;
    /* background: rgba(0, 0, 0, 0)  !important; */
    /* background-color: rgba(0, 0, 0, 0)  !important; */

    left: 369.276px;
    /* width: 219.762px; */
    top: 20%;
    /* top: 43%; */
    /* background-color: transparent !important; */
    background: url("../../asset/arrow.png") !important;
    /* background: none !important; */
    /* background-position: center center; */
}

.css-ttwr4n ::after {
    content: '';
    position: absolute;
    left: 50px;
    /* background-color: transparent; */
    /* background: none; */
    background: url("../../asset/arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.MuiTabs-indicator {
    content: '';
    position: absolute;
    left: 50px;
    /* background-color: transparent; */
    /* background: none; */
    background: url("../../asset/arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,.MuiButtonBase-root {
    /* width: 29%; */
}

.btn-up {
    border-radius: 32px !important;
    width: 172px !important;
    height: 55px !important;
}

.check {
    width: 15px !important;
    float: right !important;
    height: 30px !important;
    margin-left: 10px !important;
}

.MuiButtonBase-root,
.MuiTab-root,
.MuiTab-textColorPrimary,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: #4D6646 !important;
}

.MuiTab-textColorPrimary {
    color: #4D6646 !important;
}

/* .css-1q2h7u5.Mui-selected{
    margin-left: 103px;
} */
.rc-time-picker-input {
    height: 46px;
}

.btn-com1:hover {
    color: white !important;
}
/* .MuiButtonBase-root{
    margin-left: 60px !important;
} */