.input-group-text {
    margin-left: -10px;
    color: white;
    background: grey;
    border: 0px;
    border-radius: 32px;
}

.lg-group {
    width: 100%;
}

.lgg-group {
    width: 100%;
}

.width-don {
    width: 50%;
    background: rgb(200, 197, 197);
    padding-top: 50px;
    padding-left:14px
}

@media (min-width: 2500px) {
    .lg-group {
        width: 76%;
    }

    .lgg-group {
        width: 76%;
    }

}
.p-tag{
    font-family: open sans;
    color: black !important;
}
@media (max-width: 450px) {
    .width-don {
        width: 100%;
        background: rgb(200, 197, 197);
        padding-top: 50px
    }
}