.form-label {
    color: white;
    font-family: creamy coconut;
}
.form-control{
    font-family: open sans !important;
}
.form-check-label{
    color: white;
    font-family: open sans !important;

}
.PhoneInput--focus{
    border: 0px solid red !important;
}
.--PhoneInput-color--focus{
    border: 0px solid red !important;

}
.PhoneInputInput--foucs{
    border: 0px solid red !important;

}