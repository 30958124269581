@font-face {
  font-family: "Creamy Coconut";
  src: url("../src/fonts/Creamy Coconut.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Black";
  src: url("../src/fonts/Poppins-Black.ttf") format("opentype");
}

.nav-item a {
  font-family: "Creamy Coconut";
  font-size: large;
}

.nav-link:active {
  text-decoration: underline !important;
  background-color: rgb(37, 36, 36) !important;
}

.active {
  text-decoration: underline !important;
  background-color: rgb(37, 36, 36) !important;
}

.react-datepicker-ignore-onclickoutside {
  font-family: open sans !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Creamy Coconut";
}

h4 {
  color: white !important;
}

p,
span,
strong {
  font-family: Open Sans;
}

.dropdown-menu {
  /* margin-top: 22px !important; */
  border: 0px solid grey !important;
}

.frame-footer {
  background-color: #000 !important;
  color: #fff !important;
}

.frame-footer .nav-link {
  color: #fff !important;
}

.site-footer {
  font-size: 1em;
  padding: 0.75em 1em;
}

.site-footer .nav-item {
  padding: 0em 1em;
}

.site-footer {
  font-size: 1em;
  padding: 0.75em 1em;
  background-color: rgb(37, 36, 36) !important;
  color: #fff !important;
}

.site-footer > a {
  color: #fff !important;
}

.site-footer .nav-item {
  padding: 0em 1em;
}

.navbar {
  background-color: rgb(37, 36, 36);
}

a {
  font-family: "Century Gothic Bold", Tahoma, Geneva, sans-serif;
  /* color: white !important; */
  font-weight: 400;
  letter-spacing: 0.075em;
  line-height: 1.5;
}

.max-size-5xl,
.max-width-5xl {
  max-width: 16em !important;
}

.align-center {
  text-align: center !important;
}

body {
  font-family: "Century Gothic", "Tahoma", "Geneva", "sans-serif";
  margin: 0px !important;
}

.social-media-nav {
  margin: 1px;
}

.social-media-nav a {
  color: white;
}

.social-media-nav a:hover {
  color: white;
}

.mrg-0 a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.075em;
  line-height: 1.5;
}

.mrg-0 a:hover {
  color: white;
  text-decoration: none;
}

.email-address a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.075em;
  line-height: 1.5;
}

.email-address a:hover {
  color: white;
  text-decoration: none;
}

.tab-menu {
  width: 100%;
  border: 0px;
}

.tab-menu {
  width: 33%;
}

.px-3 a {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  color: white;
  text-decoration: none;
}

.dropdown-menu .px-3 {
  /* width: 210px; */
  margin-right: 20px;
  /* max-width: 210px */
}

.dropdown-menu .px-3:hover {
  color: #fff !important;
}

/* steper */
body {
  background: #eee;
}

#regForm {
  margin: 0px auto;
  font-family: Raleway;
  padding: 40px;
  border-radius: 10px;
}

h1 {
  text-align: center;
}

input[type="text"] {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #e6e6e6 !important;
  color: #000 !important;

  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 32px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.invalid {
  background-color: #ffdddd;
}

.tab {
  display: none;
}

button {
  background-color: #4d724ed8;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

#nextD {
  background-color: grey;
}

button:hover {
  opacity: 0.8;
}

#prevBtn {
  background-color: #bbbbbb;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #245f1c;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #4caf50;
}

.all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.thanks-message {
  display: none;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 415px) {
  .container {
    font-size: 12px;
  }
}

.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.show-tab {
  display: block !important;
}

tr {
  border: 2px solid white !important;
}

/*
rating
*/
.containerOne {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerOne:hover input ~ .checkmark {
  background-color: #ccc;
}

.containerOne input:checked ~ .checkmark {
  background-color: #2196f3;
}

.containerOne .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.containerOne input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.head-bold {
  font-weight: bold;
  color: white;
  padding-left: 2.5em;
  text-indent: -2.5em;
  font-size: 17px;
}

.head-bold-one {
  font-weight: bold;
  color: white;
  font-size: 17px;
}

.mobileView .head-bold,
.mobileView .head-bold-one {
  font-size: 14px;
}

.show-tab input,
.mobileView .head-bold {
  font-family: "Open sans";
}

.show-tab table tr,
.show-tab table th,
.show-tab table td {
  padding: 5px;
  font-size: 16px;
}

.mobileView table tr,
.mobileView table th,
.mobileView table td {
  padding: 5px;
}

#nextprevious button,
.mobileView #nextprevious button {
  border-radius: 5px;
}

.show-tab li {
  font-size: 16px;
  line-height: 2;
  font-family: "Open Sans";
}

.mobileView .show-tab li,
#regForm ul li {
  font-family: "Open Sans";
}

.ma-t-10 {
  margin-top: 10px !important;
}

.ma-t-30 {
  margin-top: 30px !important;
}

.ma-t-200 {
  margin-top: 200px !important;
}

@media screen and (max-width: 400px) {
  .ma-t-200 {
    margin-top: 20px !important;
  }

  .form-log {
    margin-top: 10px;
  }

  .log {
    margin-top: 100px;
  }
}

.flt-rt {
  float: right;
  margin-right: -98px;
}

.dropdown-menu,
.show {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item p:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 15px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #4d724ed8;
  background-color: #fff;
}

.radio-item p:after {
  text-indent: -2.5em;
}

.radio-item input[type="radio"]:checked + p:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  left: 10px;
  content: " ";
  display: block;
  background: #4d724ed8;
}

.radio-item p {
  margin-bottom: 0px !important;
}

.rc-time-picker {
  width: 100%;
}

.form-control:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

.form-control,
input[type="text"] {
  /* background-color: #000 !important; */
  /* color: #fff !important;
  border:0px solid #000 !important;
  border-bottom: 1px solid #fff !important;
  /* padding: 2px !important; */
  /* border-right: 0px solid transparent !important; */
  padding: 0.6rem 0.75rem !important;
}

.form-log {
  margin-top: 10px;
}

.PhoneInputInput {
  background: #e6e6e6;
  border: 0px solid black !important;
  color: black;
  width: 100% !important;
  border-radius: 32px !important;
  padding-left: 10px !important;
}
.PhoneInputInput:focus {
  border: 0px solid black !important;
}

.PhoneInputCountrySelect {
  background-color: #e6e6e6;
  border: 0px;
}
.PhoneInput {
  background-color: #e6e6e6 !important;
  border-radius: 32px !important;
}

.react-player__shadow {
  background: radial-gradient(
    rgba(0, 0, 0, 0.932),
    rgb(0 0 0 / 87%) 60%
  ) !important;
  border-radius: 64px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  color: red;
  display: none;
}

.mr-0 {
  margin-bottom: 2px !important;
}

#currentPageIndexTextField {
  width: 200px !important;
}

label,
a {
  font-family: open sans;
}

.sr-only {
  display: none;
}

::placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}
.ant-input:active {
  border: 0px solid black;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input:hover {
  border: 0px solid black !important;
}
.carousel-inner .active {
  text-decoration: none !important;
}
.ant-table-scroll {
  border-radius: 32px !important;
  overflow: auto !important;
  /* overflow-x: scroll !important; */
}
.ant-table-scroll::-webkit-scrollbar {
  display: none;
}
/* .ant-table-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
/* border-radius: 80px;
  height: 5px;
}  */
.ant-table-body {
  display: contents;
}

/* td,th{
  border: 0px solid grey !important;
}
.ant-table-thead > tr,.ant-table-row{
  border: 1px solid white !important;
}
.ant-table-thead > tr > th,.ant-table-tbody > tr > td{
  border:0px solid grey !important;

} */
td {
  border-bottom: 1px solid white !important;
}
.ant-spin-container {
  border: 1px solid white !important;
  border-radius: 32px !important;
}
tr:last-child > td {
  border-bottom: 0px solid grey !important;
}
.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-item {
  border: 0px solid wheat !important;
  background-color: rgba(162, 158, 158, 0) !important;
  color: white !important;
}

.ant-table-placeholder {
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
  /* background-color: grey; */
}

td,
th,
span {
  font-family: "open sans";
}

@media (max-width: 500px) {
  #root{
    margin: 0px !important;
    width: 102%;
  }
  .css-heg063-MuiTabs-flexContainer,
  .css-1anid1y,
  .css-k008qs {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .css-k008qs::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  #example-collapse-text {
    padding-left: 0px !important;
  }

  .name-um {
    margin-top: 20px !important;
  }
}
