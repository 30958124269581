* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0px;
    font-family: 'segoe ui';
  }
  
  /* .nav {
    height: 50px;
    width: 100%;
    background-color: #4d4d4d;
    position: relative;
  } */
  .logout-btn{
    margin-left: 70px;
  }
  .nav > .nav-header {
    display: inline;
  }
  
  .nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  .nav > .nav-header > .nav-title > img{
    width: 167px;
    height: 45px;
    vertical-align: middle;
  }
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
  }
  
  .nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }
  
  .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .nav > #nav-check {
    display: none;
  }
  @media (max-width:850px) {
    .logout-btn{
      margin-left: 0px !important;
    }
  }
  @media (max-width:600px) {
    .logout-btn{
      margin-left: 0px !important;
    }
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      /* background-color: #333; */
      height: 0px;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
      color: white;
    }
    .nav > .nav-links > a {
      display: block;
      width: 100%;
      color: white;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(100vh - 50px);
      overflow-y: auto;
    }
    
  }
  .nav-item,.dropdown{
    font-size: 14px;
  }
  .px-3{
    padding-right: 0px !important;
    
  }

  /* .nav-img{
    :
  } */

  #header {
    padding: 30px 0;
    height: 92px;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(37, 36, 36) !important;
    z-index: 9999;
  }

  #header #logo h1 a, #header #logo h1 a:hover {
    color: #fff;
  }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
 .navbar-toggler {
  color: rgb(247, 229, 229);
  border-color: rgba(255, 255, 255, 0.89) !important;
  margin-right: 10px;
}
.navbar-expand-lg .navbar-collapse{
  justify-content: flex-end;
  margin-right: 160px;
}
.px-login{
 float:right;
 margin-left:70px !important
}
@media screen and (max-width: 1000px) and (min-width:320px){
  #header {
    height: 150px;
    z-index: 9999999;
  }
  .px-login{
    float:right;
    margin-left:0px !important
   }

}
@media (max-width: 600px) {
.navbar-expand-lg .navbar-collapse{
  justify-content: right;
  margin-right: 0px;
  margin-left: -14px;

}
.navbar-nav{
 background-color: black;
  margin-right:0px ;
  margin-top: -21px;

}
.px-login{
  float:right;
  margin-left:0px
 }
}

.dropdown-menu:active,.dropdown-menu.show{
  display: flex !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.dropdown-item{
  color: white;
}
.dropdown-toggle::after{
  display: none !important;
}
.nav-link{
  padding: 0px !important;
}
.nav-link a:active{
  text-decoration: underline !important;
  background-color: rgba(0, 0, 0, 0);

}
.navbar-light .navbar-nav .nav-link{
  white-space: nowrap;
}
.px-4{
  margin-left: 10px;
  padding-left: 10px !important;
  padding-right: 0px !important;
}