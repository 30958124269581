.site-collapse-custom-collapse {
  background-color: #707070;
  color: white;
  margin-top: 10px;
  border-radius: 20px;
  padding: 10px;

}

.ant-collapse-header {
  color: #000000 !important;
  border-radius: 32px;
  background-color: #E6E6E6;
}
.ant-collapse>.ant-collapse-item{
  border: 0px solid grey !important;
}
.ant-collapse-borderless>.ant-collapse-item {
  /* margin: 50px; */
  /* background-color: #E6E6E6 !important; */
  border-radius: 32px;
  margin-top: 30px;
}

.ant-collapse-borderless>.ant-collapse-item:last-child,
.ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 32px;

}

.ant-comment-content {
  background: #E6E6E6;
  border-radius: 32px;
  padding: 30px;
  color: black;
}

.message-list {
  max-width: 500px;
  width: 100%;
}

.message-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-bottom: 1px solid #eeeeee;
}

.user,
.date {
  font-size: 0.625rem;
  color: #888888;
}

.user {
  min-width: 120px;
}

.message {
  flex-grow: 1;
}
.ant-collapse-content > .ant-collapse-content-box{
  padding-left: 16px !important;
  padding-right: 0px !important;
}
.ant-comment-inner{
  padding: 5px 0px !important;
}

::-webkit-input-placeholder { /* WebKit browsers */
  color:    #000000;
  opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #000000;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #000000;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #000000;
  opacity: 1;

}
input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.9; /*Change the opacity between 0 and 1*/
}

.links-div>a{
  color: white !important
}
.div-b>a{
  color: black 
}
.reply-div{
  margin-left: 30px;
}
@media (max-width: 500px) {
  .reply-div{
    margin-left: 0px;
  }
}