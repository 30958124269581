a {
    text-decoration: none !important;
    color: white;
    cursor: pointer;
    font-family: open sans;
}

.links-div>a {
    color: white !important;
    font-family: open sans;
}

.contact-us-text {
    color: white;
    text-align: right;
    margin-right: 90px;
}

.contact-details-page {
    margin-bottom: 30px;
    text-align: right;
    margin-right: 90px;
}

.copy-right {
    margin-right: 85px;
    color: white;
    font-family: open sans
}

@media (max-width: 500px) {
    .contact-details-page {
        text-align: center;
        color: white;
        margin-right: 0px;
        margin-top: 20px;
    }

    .contact-us-text {
        text-align: center;
        color: white;
        margin-right: 0px;
        margin-top: 20px;
        /* text-align: right; */
        /* margin-right: 90px ; */
    }
    .copy-right {
        margin-left: 50px;
        color: white;
        font-family: open sans;
        text-align: center !important;
    }
}